@import "~reservation-panel-styles/panel/variables";


$button-height: 4rem;
$button-border-radius: 0.4rem;
$border-radius: 0.4rem;
$input-border-radius: 0.8rem;
$input-height: 5.6rem;

// font-sizes:
$xxs-font-size: 1rem;
$xs-font-size: 1.1rem;
$small-font-size: 1.2rem;
$small-base-font-size: 1.3rem;
$base-font-size: 1.4rem;
$base-ms-font-size: 1.5rem;
$base-m-font-size: 1.6rem;
$base-ml-font-size: 1.7rem;
$m-font-size: 1.8rem;
$ml-font-size: 2rem;
$l-font-size: 2.2rem;
$x-font-size: 2.4rem;
$xm-font-size: 2.5rem;
$xl-font-size: 2.6rem;
$xll-font-size: 2.8rem;
$xxl-font-size: 3.2rem;
$xxxl-font-size: 3.5rem;
$xxxxml-font-size: 4rem;
$xxxxl-font-size: 4.5rem;
$xxxxxl-font-size: 5rem;

// basic colors:
$white-color: #fff;
$black-color: #000;
$grey-text-color: #666;
$text-main-color: #111927;
$text-secondary-color: #6C737F;
$theme-main-color: #16B364;

// default theme color
$primary-color: #16B364;
$secondary-color: #29235c;
$success-color: #16b364;
$warning-color: #ff9f43;
$error-color: #ea5455;
$danger-color: #e30040;
$info-color: #009fe3;
$dark-color: #263238;
$section-title-color: #5E5873;
$error-block-color: #bc5a45;
$border-color: #d2d8dd;
$gray-color: #6c757d;
$gray-text-color: #888888;
$divider-color: #F2F4F7;
$btn-disabled-background-color: #E6E7E8;
$btn-disabled-text-color: #848A94;
$input-border-color: #F2F4F7;
$label-color: #6C737F;

// breakpoints:
$breakpoint-xsmall: 480px;
$breakpoint-small: 768px;
$breakpoint-medium: 992px;
$breakpoint-large: 1024px;
$breakpoint-xlarge: 1280px;
$breakpoint-xxlarge: 1400px;
$breakpoint-full: 1600px;

// Body
$body-bg: #f8f8f8;
$body-color: #6e6b7b;

$alert-border-radius: 0.8rem;

// alert colors:
$alert-error-background-color: #FEE4E2;
$alert-error-color: #7A271A;
$alert-error-icon-color: #7A271A;
$alert-success-background-color: #E8F8F5;
$alert-success-color: #08493f;
$alert-success-icon-color: #08493f;
$alert-warning-background-color: #fdead7;
$alert-warning-color: #772917;
$alert-warning-icon-color: #772917;
$alert-info-background-color: #CFF9FE;
$alert-info-color: #164C63;
$alert-info-icon-color: #164C63;
