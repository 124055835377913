.form-control {
    &.switch {
        .switch-label {
            padding: 1.2rem;

            .switch-input {
                &:checked {
                    + .switch-slider {
                        background-color: $input-border-color;

                        &::after {
                            border-bottom: 0.2rem solid $white-color;
                            border-radius: 0.1rem;
                            border-right: 0.2rem solid $white-color;
                            height: 0.8rem;
                            opacity: 1;
                            position: absolute;
                            right: 0.45rem;
                            top: 0.2rem;
                            transform: rotate(45deg);
                            width: 0.4rem;
                        }

                        &::before {
                            background-color: $text-main-color;
                        }
                    }
                }

                + .switch-slider {
                    &::after {
                        content: "";
                        opacity: 0;
                        transition: opacity 0.75s ease-in-out;
                    }
                }
            }

            .switch-label-text {
                color: $text-main-color;
                font-weight: 400;

                .link-label {
                    color: $text-main-color;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
        }
    }
}