.view {
    background: $white-color;
    display: flex;
    min-height: 100vh;
    width: 100vw;

    .reservation-card-view {
        align-items: center;
        background-size: cover;
        display: flex;
        flex: 1;
        justify-content: center;
        padding: 1.5rem;
    }
}

@media only screen and (max-width: $breakpoint-medium) {
    .view {
        .reservation-form-card {
            align-items: flex-start;
        }
    }
}