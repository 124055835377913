.card {
    background-color: $white-color;
    border-radius: 2rem;
    -webkit-box-shadow: 0 0 2rem -1rem rgba(66, 68, 90, 1);
    -moz-box-shadow: 0 0 2rem -1rem rgba(66, 68, 90, 1);
    box-shadow: 0 0 2rem -1rem rgba(66, 68, 90, 1);
    display: flex;
    flex-direction: column;
    font-family: $jakarta-font-family;
    max-width: 68rem;
    min-width: 40rem;
    width: 100%;

    .card-header {
        align-items: center;
        border-bottom: 0.1rem solid $divider-color;
        display: flex;
        gap: 2.4rem;
        padding: 2.4rem;

        .logo-container {
            border: 0.2rem solid $divider-color;
            border-radius: $border-radius;
            height: 100%;
            padding: 0.8rem;
            width: 8rem;

            .logo {
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: $border-radius;
                flex:1;
                padding-bottom: 100%;
            }
        }

        .restaurant-details {
            align-items: flex-start;
            color: $text-main-color;
            display: flex;
            flex-direction: column;
            text-align: center;

            .name {
                font-size: $xll-font-size;
                font-weight: 700;
                line-height: 3.2rem;
                margin-bottom: 0.4rem;
            }

            .details {
                color: $text-secondary-color;
                font-size: $base-font-size;
                font-weight: 700;
                line-height: 2rem;
            }
        }
    }

    .card-body {
        display: flex;
        flex: 1;
        padding: 2.4rem;

        .initial-description {
            border-radius: 0.8rem;
            max-height: 20rem;
            overflow: auto;
        }

        .wizard-container {
            display: flex;
            flex: 1;

            .step {
                display: flex;
                flex: 1;
                flex-direction: column;
                gap: 2.4rem;

                .title-container {
                    display: flex;
                    justify-content: center;

                    .title {
                        background: $divider-color;
                        border-radius: 0.4rem;
                        color: $text-main-color;
                        font-size: $m-font-size;
                        font-weight: 700;
                        line-height: 1.6rem;
                        padding: 1rem 1.2rem;
                        text-transform: uppercase;
                    }
                }

                &.reservation-details-step {
                    .form-control {
                        &.room-select {
                            flex: 3;
                        }

                        &.show-plan-btn {
                            border: 0;
                            display: flex;
                            flex: 1 1;
                            padding: 0;
                        }
                    }
                }

                &.restaurant-plan-step {
                    display: flex;
                    flex-direction: column;

                    .restaurant-plan-container {
                        display: flex;
                        flex: 1;

                        .restaurant-plan {
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            flex: 1;
                            height: 43rem;
                            margin: 0 2rem;
                        }
                    }

                }

                &.purchaser-details-step {
                    .reservation-summary {
                        color: $text-main-color;

                        .reservation-summary-details {
                            align-items: center;
                            display: flex;
                            flex-direction: column;
                            gap: 0.3rem;
                            margin-top: 0.6rem;
                            padding: 0 1rem;

                            .reservation-summary-time {
                                display: flex;
                                font-size: $ml-font-size;
                                font-weight: 700;
                                gap: 1.2rem;
                            }

                            .reservation-summary-count {
                                display: flex;
                                font-size: $base-font-size;
                                gap: 0.6rem;
                            }
                        }
                    }

                    .reservation-fee {
                        display: flex;
                        justify-content: flex-end;

                        p {
                            color: $text-main-color;
                            font-size: $m-font-size;
                            font-weight: 700;
                            line-height: 2.2rem;

                            span {
                                color: $primary-color;
                            }
                        }
                    }
                }

                &.privacy-policy-step {
                    .privacy-policy-container {
                        height: 15rem;
                        overflow: auto;
                    }
                }
            }

            .form-footer {
                display: flex;
                flex-direction:row-reverse;
                justify-content: space-between;
            }
        }
    }
}

@media only screen and (max-width: $breakpoint-medium) {
    .card {
        .card-header {
            flex-direction: column;
            gap: 1.2rem;
            padding: 1.5rem 3rem;

            .logo-container {
                width: 8rem;
            }

            .restaurant-details {
                align-items: center;
            }
        }

        .card-body {
            padding: 1.5rem;

            .wizard-container {
                .initial-description {
                    max-height: unset;
                }

                .step {
                    &.reservation-details-step {
                        .form-control {
                            &.show-plan-btn {
                                flex: 2.5;
                            }
                        }
                    }
                }
            }
        }

    }
}
