@import "variables";

.btn-primary {
    align-items: center;
    background-color: $primary-color;
    border-radius: 0.8rem;
    box-sizing: border-box;
    color: $white-color;
    display: flex;
    font-size: $base-m-font-size;
    font-weight: 800;
    height: 4.8rem;
    padding: 0.8rem 2.4rem;
    transition: all 0.5s ease-in-out;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.25;
    }

    .btn-icon {
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        height: 1.8rem;
        margin-right: 0.8rem;
        width: 1.8rem;

        &.plus-icon {
            background-image: url("../images/plus_icon.png");
        }
    }
}

.btn-primary-outline {
    background-color: $white-color;
    border: 0.2rem solid $primary-color;
    border-radius: 0.8rem;
    box-sizing: border-box;
    color: $primary-color;
    font-size: $base-m-font-size;
    font-weight: 800;
    height: 4.8rem;
    padding: 0.8rem 2rem;
    transition: all 0.5s ease-in-out;

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
    }
}

.btn-secondary {
    align-items: center;
    background-color: transparent;
    box-sizing: border-box;
    color: $text-main-color;
    display: flex;
    font-size: $base-font-size;
    font-weight: 500;
    height: 3.6rem;
    justify-content: center;
    padding: 0.8rem;
    transition: all 0.5s ease-in-out;

    .btn-icon {
        background: no-repeat url("../images/arrow_back.png");
        background-position: center;
        display: flex;
        height: 1.8rem;
        margin-right: 0.8rem;
        width: 1.8rem;
    }

    &:hover {
        cursor: pointer;
        filter: brightness(90%);
        text-decoration: underline;
    }
}