@import "variables";
@import "~reservation-panel-styles/normalize";
@import "~reservation-panel-styles/panel/style";
@import 'button';
@import 'switch';
@import 'reservation-form-card';
@import 'view-host';


.sr-only {
    border: 0 !important;
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 0.1rem !important;
    margin: -0.1rem !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 0.1rem !important;
}

p {
    margin-bottom: 0;
}

